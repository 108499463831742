.Container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: var(--lc-pitboss__gray_700);
    border-radius: 0.2em;
    position: relative;
    height: 100%;
    .TableWrapper {
        width: 100%;
        height: calc(100% - 3.5em);
    }
}

.RescanButtons {
    display: flex;
    height: 20%;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: var(--lc-pitboss__gray_700);
    border: 1px solid rgba(75, 75, 75, 0.76);
    border-right: 0;
    border-left: 0;
}
.Disabled {
    pointer-events: none;
    opacity: 0.2;
    user-select: none;
}

.ActionButton {
    width: 48%;
    height: 90%;
}
