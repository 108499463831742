:global {
    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-selection-item {
        flex: none;
    }
}
.PitBossNewWindow {
    width: 100%;
    height: 100%;
    background-color: var(--lc-pitboss__black_400);
    display: flex;
    flex-direction: column;
}

.IFramesContainer {
    width: 100%;
    margin: 0.8em 0;
    padding: 0 1.2em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}
.StreamSwitchRadioButtons {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.TabsWrapper {
    padding: 0 1.2em 0.8em 1.2em;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
}
.ErrorContentWrapper {
    text-align: center;
}
