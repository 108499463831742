.VideoContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 1em;
    box-sizing: border-box;
    .Header {
        width: 100%;
        height: 2.4em;
        background: var(--lc-pitboss__gray_700);
        display: flex;
        align-items: center;
        border-radius: 0.6em;
    }
    .VideoContainer {
        display: flex;
        height: calc(100% - 2.4em);
    }

    .VideoIsZoomInClass {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 0.8em;
        .VideoWrapper {
            width: 100%;
            box-sizing: border-box;
        }
        .ChatWrapper {
            width: 100%;
            height: 100%;
            margin-top: 0.8em;
            overflow: hidden;
        }
    }
}

.VideoContentWrapper {
    width: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    grid-template-rows: min-content;
    align-content: flex-start;
    grid-gap: 0.5em;
    &.GridWrapperInFullScreen {
        max-height: 100%;
        align-content: start;
        max-width: 50%;
        font-size: 0.9em;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

.GameWrapper {
    width: 100%;
    position: relative;
    padding-top: 72%;
}

.ErrorContentWrapper {
    text-align: center;
}
