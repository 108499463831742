.GameContentImage {
    height: 100%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
}

.Blur {
    filter: blur(0.25em);
}

.FadeOut {
    animation-name: fade-in-out;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}

@keyframes fade-in-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
