:global {
    .ant-select-dropdown,
    .ant-picker-dropdown {
        z-index: 99999999 !important;
    }
    .ant-select-item,
    .ant-select-selection-item {
        color: var(--lc-pitboss__black_900);
    }
    .ant-radio-wrapper {
        color: var(--lc-pitboss__black_900);
    }

    .ant-radio-inner {
        background-color: var(--lc-pitboss__white_900);
    }
    .ant-radio-inner:after {
        background-color: var(--lc-pitboss__yellow_800);
    }
}

.ModalWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 1001;
    color: var(--lc-pitboss__black_900);
    width: 24em;
}

.NotificationWrapper {
    min-height: 10%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    max-height: 60%;
    background-color: var(--lc-pitboss__white_900);
    width: 100%;
    .ModalHeader {
        padding: 0.9em 1em;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: var(--lc-pitboss__white_900);
        color: var(--lc-pitboss__black_600);
        box-sizing: border-box;
        font-family: var(--roboto-condesed-bold);
        box-shadow: 0 0 10px 1px rgba(26, 26, 26, 0.44);
        z-index: 1;
        .CloseIconContainer {
            width: 1.2em;
            cursor: pointer;
        }
    }

    .ModalBody {
        overflow: auto;
        background-color: var(--lc-pitboss__white_700);
        padding: 1.6em;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        color: var(--lc-pitboss__black_600);
        font-family: var(--roboto-medium);
        font-size: 1.15em;
        text-align: center;
        .Message {
            width: 100%;
        }

        .PasswordField {
            margin-top: 10px;
            width: 100%;
        }
    }

    .ModalFooter {
        background-color: var(--lc-pitboss__white_700);
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 2em;
        .ModalButton {
            width: 47%;
            min-height: 40px;
            max-width: 170px;
        }
    }
}

.FormContainer {
    margin-top: 15px;
    width: 100%;
}

.FormItem {
    background-color: var(--lc-pitboss__white_900) !important;
    width: 100%;
    :global {
        .anticon {
            color: var(--lc-pitboss__black_900);
        }
    }
}

.TripleDicesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 2em;
}

.TripleDices {
    display: flex;
    justify-content: space-between;
}

.Dice {
    width: 2em;
    height: 2em;
    margin: auto 0.3em;
}

.ErrorMessages {
    font-size: 0.7em;
    color: var(--lc-pitboss__red_500);
    height: 1.5em;
}

.ErrorContainer {
    height: 0.5em;
}
