.CuttingCardsTableWrapper {
    width: 100%;
    height: 100%;
    :global {
        .ant-table .ant-badge-status-dot {
            position: absolute;
            top: 0.5em;
        }
        .ant-table-column-sort {
            background-color: var(--lc-pitboss__gray_700);
        }
    }
}
