.Footer {
    width: 100%;
    height: 100%;
    padding: 3px 5px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}

.Footer .Limits p {
    color: var(--lc-pitboss__yellow_800);
    justify-content: flex-end;
}

.Footer p {
    margin: 0;
    font-size: 14px;
}
.RoundId {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.NameIdLanguageDealer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.LeftSide {
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--roboto-regular);
    align-items: flex-start;
}
.RightSide {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-family: var(--roboto-regular);
    height: 100%;
}
.AmountContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 50%;
    & > p {
        color: var(--lc-pitboss__yellow_800);
        font-family: var(--roboto-bold);
    }
}
.AmountContainer .BalanceIconWrapper {
    font-size: 16px;
    width: 0.8em;
    height: 0.8em;
    display: flex;
    margin-right: 0.15em;
}
.FlagAndGameNameWrapper {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
}

.GameInfoFlag {
    position: relative;
    width: 14%;
    margin-right: 3%;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
}
.IconWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.GameName {
    font-family: var(--roboto-bold);
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.NoMistake {
    background-color: var(--lc-pitboss__gray_700);
}

.NoMistake .LeftSide,
.NoMistake .RightSide {
    color: var(--lc-pitboss__white_900);
}

.Mistake {
    background-color: var(--lc-pitboss__red_500);
}

.InProgress {
    background-color: var(--lc-pitboss__yellow_400);
}

.Mistake .LeftSide p,
.Mistake .RightSide p,
.Mistake .Limits p {
    color: var(--lc-pitboss__white_900);
}

.Mistake svg {
    fill: var(--lc-pitboss__white_900);
}

.InProgress .LeftSide p,
.InProgress .RightSide p,
.InProgress .Limits p {
    color: var(--lc-pitboss__black_900);
}

.InProgress svg {
    fill: var(--lc-pitboss__black_900);
}

// Footer in zoom mode

.FooterIsZoomInMode {
    background-color: var(--lc-pitboss__black_600);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 1em;
}

.FooterIsZoomInMode .NameIdLanguageDealer {
    width: 82%;
    align-items: center;
}

.FooterIsZoomInMode .RightSide,
.FooterIsZoomInMode .LeftSide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 55%;
}

.FooterIsZoomInMode .RightSide {
    width: 40%;
    flex-direction: row-reverse;
    padding: 0 0.3em;
    border-right: 0.5px solid rgba(128, 128, 128, 0.2);
}

.FooterIsZoomInMode .FlagAndGameNameWrapper,
.FooterIsZoomInMode .RoundId {
    font-family: var(--roboto-bold);
    border-right: 0.5px solid rgba(128, 128, 128, 0.2);
}

.FooterIsZoomInMode .RoundId {
    width: 55%;
    padding: 0 0.5em;
}
.FooterIsZoomInMode .FlagAndGameNameWrapper {
    width: 45%;
}
.DealerName {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.FooterIsZoomInMode .DealerName {
    position: relative;
    font-family: var(--roboto-bold);
}

.PlayersAndWatchers {
    height: 1em;
    display: flex;
    align-items: center;
}
