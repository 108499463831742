.ActionPopOverContent {
    width: 10.7em;
    button {
        width: inherit;
        height: 3em;
        background-color: var(--lc-pitboss__white_900);
        display: block;
        font-size: 1em;
        text-align: left;
        border: 0;
        border-bottom: 0.1em solid var(--lc-pitboss__white_900);
        margin: 0;
        padding: 0.7em 0;
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
    }
    button:hover {
        color: var(--lc-pitboss__yellow_800) !important;
    }
    button:last-child {
        color: var(--lc-pitboss__red_500);
        border-bottom: none;
    }
}

.PopoverTitle {
    cursor: pointer;
    white-space: nowrap;
    color: var(--lc-pitboss__yellow_800);
}
