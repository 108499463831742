.GameSettings {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .ControlsRow {
        width: 100%;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.4em;
    }
}

.GameSettingsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--lc-pitboss__black_900);
    background-color: var(--lc-pitboss__white_500);
    font-family: var(--roboto-medium);
    border: 0.1em solid #cecece;
    border-radius: 2px;
    cursor: pointer;
}

.FullScreenButton {
    width: 1.5em;
    height: 1.5em;
    font-weight: bold;
}

.DMSButtonWrapper {
    width: 3em;
    height: 1.5em;
}

.VideoSecondLayerRightSide {
    display: flex;
    height: 1.5em;
    width: 5.2em;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8em;
}
