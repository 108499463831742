.MutedInfoContainer {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    background-color: var(--lc-pitboss__black_900);
    border-radius: 4px;
}

.MutedIconContainer {
    height: 1.4em;
    width: 1.4em;
}
