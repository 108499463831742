:root {
    --lc-pitboss__black_900: rgb(0, 0, 0);
    --lc-pitboss__black_900-61: rgba(0, 0, 0, 0.61);
    --lc-pitboss__black_600: rgb(27, 27, 27);
    --lc-pitboss__black_500: rgb(31, 31, 31);
    --lc-pitboss__black_400: rgb(38, 38, 38);
    --lc-pitboss__black_400-80: rgba(38, 38, 38, 0.8);
    --lc-pitboss__black_350: rgb(51, 51, 51);

    --lc-pitboss__gray_850: rgb(77, 77, 77);
    --lc-pitboss__gray_700: rgb(51, 51, 51);
    --lc-pitboss__gray_600: rgb(204, 204, 204);
    --lc-pitboss__gray_500: rgb(85, 85, 85);
    --lc-pitboss__gray_300: rgb(112, 112, 112);
    --lc-pitboss__gray_200: rgb(136, 136, 136);
    --lc-pitboss__gray_100-10: rgba(178, 165, 165, 0.1);

    --lc-pitboss__white_900: rgb(255, 255, 255);
    --lc-pitboss__white_700: rgb(242, 242, 242);
    --lc-pitboss__white_500: rgb(206, 206, 206);

    --lc-pitboss__red_500: rgb(255, 0, 0);

    --lc-pitboss__yellow_800: rgb(255, 183, 0);
    --lc-pitboss__yellow_400: rgb(255, 235, 27);
    --lc-pitboss__brown_500: rgb(211, 160, 97);

    --lc-pitboss__green_600: rgb(0, 153, 0);
    --lc-pitboss__green_500: rgb(67, 157, 34);
    --lc-pitboss__green_400: rgb(20, 190, 6);
    scrollbar-width: thin;
}
::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--lc-pitboss__gray_200);
    border-radius: 1em;
}
::-webkit-scrollbar-thumb:hover {
    background-color: var(--lc-pitboss__gray_500);
}

.ant-empty-description {
    color: white !important;
}

.AppWrapper {
    width: 100%;
    height: 100%;
    font-size: 20px;
    background-color: var(--lc-pitboss__black_500);
    overflow: hidden;
    user-select: text !important;
}
.backDrop {
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: var(--lc-pitboss__black_400-80);
    z-index: 1;
}

.BlockUserWrapper {
    font-size: 14px;
    position: fixed;
    top: 15%;
    right: 50%;
    height: 525px;
    width: 336px;
    transform: translateX(50%);
    z-index: 2;
}
.PopUpContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
}

.FormItemLabel {
    color: var(--lc-pitboss__black_400);
    font-family: var(--roboto-regular);
    font-size: 14px;
}

.cardItem {
    width: 2em;
    height: 3em;
    margin: 0 auto;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container,
.ant-table-body {
    height: 100%;
}
.ant-select .ant-select-selection-placeholder {
    flex: none;
}
.ant-select .ant-select-selection-item {
    flex: none;
}
div.ant-table-body {
    overflow-y: auto !important;
}
/*////Apply this class to table and set scroll props to y - calc(100% - #HEADER HEIGHT)*/
.height100 {
    height: 100%;
}
.blur-background {
    filter: blur(4px);
}
