.FormItem {
    background-color: var(--lc-pitboss__white_900);
    width: 100%;

    :global {
        .anticon {
            color: var(--lc-pitboss__black_900);
        }
    }
}
