.StreamWrapper {
    width: 49.5%;
    .Iframe {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        .Muted {
            position: absolute;
            bottom: 50px;
            right: 10px;
            z-index: 1;
        }

        .PlayersSeatsContainer {
            position: absolute;
            z-index: 1;
            right: 10px;
            bottom: 15px;
            display: flex;
            align-items: center;
            font-size: 1.5em;
            height: 1em;
        }
    }
    .Layer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
    }

    & iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0.4em;
    }
}
.LocalStreamContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}
.SoundSliderContainer {
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    z-index: 2;
}
