.TabContainer {
    width: 100%;
    height: 100%;

    :global {
        .ant-tabs,
        .ant-tabs-content {
            height: 100%;
            font-size: 1em;
        }

        .ant-tabs-tabpane {
            height: 100%;
        }

        .ant-tabs-content-holder {
            flex: 1;
        }

        .ant-tabs .ant-tabs-nav {
            background-color: var(--lc-pitboss__gray_700);
            box-shadow: 0 3px 6px #00000080;
            border-radius: 4px;
            padding: 0 1em;
        }
    }
}

.DiceWrapper {
    display: flex;

    .Dice {
        width: 2em;
        height: 2em;
        margin-right: 0.5em;

        &:last-child {
            margin-right: 0;
        }
    }
}
