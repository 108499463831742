.HeaderElements {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    padding: 0 1.2em;
    height: 3.8em;
    flex-shrink: 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    box-shadow: 0 0.15em 0.3em var(--lc-pitboss__black_900);
    background-color: var(--lc-pitboss__gray_700);
}
