.PitBossNewWindow {
    width: 100%;
    height: 100%;
    background-color: var(--lc-pitboss__black_400);
    display: flex;
    flex-direction: column;
    color: var(--lc-pitboss__white_900);
}
.RecordedStreamHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 1.2em;
    height: 3.8em;
    border-radius: 0.2em;
    box-sizing: border-box;
    box-shadow: 0 0.15em 0.3em var(--lc-pitboss__black_900);
    background-color: var(--lc-pitboss__black_350);
    color: var(--lc-pitboss__white_900);
}

.HighLightedText {
    font-weight: 700;
    margin-left: 0.5em;
}

.VideoWrapper {
    margin: 0.8em auto 0;
    height: calc(100% - 3.8em - 0.8em); // subtract height of the header and gap
}
