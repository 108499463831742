.MessageTag {
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    margin-right: 0.3em;
    text-align: center;
    border-radius: 0.4em 0.4em 0 0;
    box-shadow: 0 -4px 8px #000000;
}
.MessageText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    margin: 0;
    font-size: 0.6em;
    font-family: Roboto_Bold, sans-serif;
    padding: 0.5em;
}
