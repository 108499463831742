.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.RescanCardsContainer {
    width: 21%;
    height: 100%;
}

.Actions {
    width: 31%;
    height: 100%;
}

.EventsTable {
    width: 45%;
    height: 100%;
}
