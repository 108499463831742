.ActionMistakes {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--lc-pitboss__gray_700);
    border-radius: 0.2em;
    position: relative;
    padding: 2px 1em;
    overflow-y: auto;
    max-height: 100%;
    .ActionsContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        .ActionTitle {
            color: var(--lc-pitboss__white_900);
            font-family: var(--roboto-medium);
        }
        .ActionButtons {
            box-sizing: border-box;
            display: grid;
            align-content: space-between;
            grid-template-columns: 32% 32% 32%;
            grid-auto-rows: 2.2em;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            margin-top: 10px;
        }
    }

    .MistakesContainer {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .LeftColumn {
            width: 45%;
            display: flex;
            flex-direction: column;
            .MistakeButton {
                border-radius: 4px;
                font-size: 0.7em;
                height: 3.5em;
                font-family: var(--roboto-medium);
            }
            .Title {
                display: block;
                margin: 5px 0;
            }
        }
        .RightColumn {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            margin-top: 31px;
            .PhoneIconWrapper {
                font-size: 0.7em;
                height: 3.5em;
                width: 3.5em;
                cursor: pointer;
            }
        }
    }
}
