@keyframes test {
    100% {
        background-color: var(--lc-pitboss__gray_700);
    }
}

.ActivePlayers {
    height: 100%;
    width: 100%;
    .SwitchBoxContainer {
        display: flex;
        align-items: center;
        padding-bottom: 1em;
        color: var(--lc-pitboss__white_900);
        span:last-child {
            opacity: 0.7;
        }
        &.Toggled {
            span:last-child {
                opacity: 1;
            }
            span:first-child {
                opacity: 0.7;
            }
        }
        .ToggleWrapper {
            width: 2.5em;
            height: 1.5em;
            font-size: 0.8em;
            margin: 0 0.5em;
        }
    }
}
