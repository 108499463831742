.GameContainer {
    width: 100%;
    box-sizing: border-box;
    border: 0.1em solid transparent;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 0.6em;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 1;
    &.Selected {
        border: 0.1em solid var(--lc-pitboss__yellow_800);
    }
    &.VideoZoomed {
        position: relative;
        padding-bottom: 6%;
        .MutedInfoContainer {
            bottom: 4%;
        }
    }
    .VideoContent {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
        box-sizing: border-box;
    }
    .MutedInfoContainer {
        position: absolute;
        bottom: 12%;
        right: 0;
        z-index: 1;
    }
}
.HoveredOverlayContent {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s;
    box-sizing: border-box;
    background-color: var(--lc-pitboss__black_900-61);
}

.HoveredOverlayContent.SoundIsOn {
    opacity: 1;
    background-color: transparent;
}

.GameContainer:hover .HoveredOverlayContent {
    opacity: 1;
    z-index: 1;
}

.MistakeAndWatch {
    position: absolute;
    bottom: 22%;
    width: 100%;
    left: 0;
    padding: 0 0.5em 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
}

.ViewersPlayers {
    height: 1em;
    font-size: 16px;
}
.NotificationContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    .StatusText {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

.DisplayText {
    font-size: 1em;
    font-family: var(--roboto-bold);
    color: var(--lc-pitboss__white_900);
}

.DisplayText.Closing {
    color: var(--lc-pitboss__red_500);
}

.DisplayText.Opening {
    color: var(--lc-pitboss__green_400);
}

.DisplayText.IsPaused {
    color: var(--lc-pitboss__yellow_800);
}

.DisplayText.AllSeatsTaken {
    position: absolute;
    bottom: 0;
}

.Countdown {
    font-size: 1em;
    font-family: var(--roboto-bold);
    color: var(--lc-pitboss__white_900);
}

.Footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 15.8%;
}

.FooterIsZoomInMode {
    padding-bottom: 6%;
}
.Shadow {
    box-shadow: 0 -4px 8px #000000;
}
