.Container {
    display: flex;
    width: 100%;
    height: 100%;
}

.RadioButton {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 1em;
}
