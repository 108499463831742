.ChatContainer {
    width: 100%;
    height: 100%;
}
.Loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    transform: translate(-50%, -50%);
    color: var(--lc-pitboss__white_900);
}
