.SoundControls {
    display: flex;
    align-items: center;
    width: 1.4em;
    height: 1.4em;
}
.SoundButton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--lc-pitboss__black_900);
    background-color: var(--lc-pitboss__white_500);
    font-family: var(--roboto-medium);
    border: 0.1em solid #cecece;
    border-radius: 2px;
    cursor: pointer;
}
.SliderWrapper {
    width: 4.5em;
}
